<template >
  <div>
    <div class="" :class="mode ? '' : 'performance__night'">
          <div class="app-modal__body p-5 pb-0">
            <div class=" rounded-sm w-100 " :class="mode ? 'table__myday' : 'table__mynight'">
                <el-form
                    ref="form"
                    status-icon
                    :model="form"
                    :rules="rules"
                    >
                  <el-row :gutter="20">
                    <el-col :span="24">
                      <div class="app-form__group mb-4">
                        <span :class="mode ? 'text__day2' : 'text__night2'"  class="input--label d-block mb-2">
                          {{ $t("message.employees") }}
                        </span>
                        <select-staff
                          :placeholder="$t('message.employees')"
                          :size="'medium'"
                          :id="form.staff_ids"
                          :multiple="true"
                          v-model="form.staff_ids"
                        ></select-staff>
                      </div>
                    </el-col>
                    <el-col :span="24">
                      <div class="app-form__group mb-4">
                        <span :class="mode ? 'text__day2' : 'text__night2'"  class="input--label d-block mb-2">
                          {{ $t("message.time") }}
                        </span>
                          <el-date-picker
                            v-model="form.punch_time"
                            type="datetime"
                            :size="'medium'"
                            style="width:100%"
                            :placeholder="$t('message.time')"
                            :class="mode ? 'input__day' : 'input__night'"
                          />
                      </div>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item :class="mode ? 'input__day' : 'input__night'" :label="$t('message.attendance_type')"
                          prop="attendance_type"
                          class="label_mini" >
                          <el-select
                              :placeholder="$t('message.attendance_type')"
                              class="d-block"
                              v-model="form.punch_state"
                              :size="'medium'"
                              filterable
                              clearable
                              >
                              <el-option
                              :label="$t('message.entrance')"
                              :value="0"
                              ></el-option>
                              <el-option
                                :label="$t('message.exit')"
                                :value="1"
                              ></el-option>
                              <el-option
                                :label="$t('message.entrance')+'(обеда)'"
                                :value="3"
                              ></el-option>
                              <el-option
                              :label="$t('message.exit')+'(обеда)'"
                              :value="2"
                            ></el-option>
                            
                          </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item
                          :class="mode ? 'input__day' : 'input__night'"
                          :label="$t('message.comment')"
                          prop="comment"
                          class="label_mini">
                          <el-input
                              type="textarea"
                              :rows="4"
                              :placeholder="$t('message.comment')"
                              :inputValue="form.comment"
                              v-model="form.comment"
                              class="text-area-el"
                          ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
              <div>
                <crm-store-update-close
                    :permission="$options.name"
                    :button_type="'store'"
                    :loading="loadingButton"
                    @c-submit="submit(true)"
                    @c-close="close()"
                ></crm-store-update-close>
            </div>
          </div>
          <!-- end app-modal__body -->
      </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import moment from "moment";
import selectStaff from "@/components/filters/inventory/select-staff";
/**
* Drawer chaqirish kerak drawerni yopish uchun
*/
import form from "@/utils/mixins/form";
export default {
  mixins: [form, drawer],
  name: "CompanyController",
  components:{
    selectStaff
  },
  data() {
      return {
        imageList: [],
      };
  },
  created() {},
  computed: {
      ...mapGetters({
          rules: "attendance/rules",
          model: "attendance/model",
          columns: "attendance/columns",
          mode: "MODE"
      }),
  },
  methods: {
      ...mapActions({
          save: "attendance/store",
           empty: "attendance/empty",
      }),         
      submit(close = true) {
        this.form.punch_time =   moment(this.form.punch_time).format("YYYY-MM-DD HH:mm:ss"),
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          this.empty();
                          this.fileList = [];
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().closeModal();
                          this.parent().parent().refreshData();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
      },
      close(){
        this.parent().closeDialogModalNew();
      }
  },
};
</script>
<style  lang="scss">
.company_info .el-textarea__inner{
min-height: 150px !important;
}
.my-upload .el-upload-list {
  position: relative;
  z-index: 2;
}
.my-upload .el-upload.el-upload--picture-card {
  position: absolute;
  left: 0px !important;
  z-index: 1;
  width: 91%;
  line-height: 150px;
  vertical-align: top;
  
}
.my-upload .upload-demo {
  position: relative;
}



</style>
